<template>
  <div v-if="$canAndRulesPass(user, 'evaluations.see_in_menu')">
    <page-heading :heading="pageTitle">
      <span slot="help"></span>
      <div slot="right"></div>
    </page-heading>
    <div v-loading="loading">
      <el-card
        v-for="(evaluation, index) in evaluations"
        :key="index"
        class="evaluation"
      >
        <div>
          <h2>{{ $companyConfiguration.settings.getEnum('evaluation_headers')[index] }}</h2>
          <el-table :data="evaluation" border>
            <el-table-column prop="name" label="Оценка" width="500" v-slot="scope">
              <click-to-edit
                type="textarea"
                v-model="scope.row.name"
                @input="update(scope.row)"
                :disabled="!$canAndRulesPass(scope.row, 'evaluations.update')"
              />
            </el-table-column>
            <el-table-column prop="description" label="Описание" v-slot="scope">
              <click-to-edit
                type="textarea"
                v-model="scope.row.description"
                @input="update(scope.row)"
                :disabled="!$canAndRulesPass(scope.row, 'evaluations.update')"
              />
            </el-table-column>
          </el-table>
        </div>
      </el-card>
    </div>
  </div>
</template>

<script>
  import ClickToEdit from "@/components/ClickToEdit";
  import PageHeading from "@/components/PageHeading.vue";
  import requestSender from "@/api/base/requestSender";
  import {mapGetters} from "vuex";

  export default {
    name: 'Evaluations',
    components: {PageHeading, ClickToEdit},
    mixins: [],
    computed: {
      ...mapGetters(['user']),
      pageTitle(){
        return document.title;
      },
    },
    mounted() {
      this.getEvaluations();
    },
    data() {
      return {
        loading: false,
        evaluations: [],
      }
    },
    methods: {
      getEvaluations() {
        this.loading = true;

        requestSender('get', 'evaluation/search', {
          expand: ['permissions'].join()
        })
          .then(({evaluations}) => {
            this.evaluations = evaluations;
          })
          .finally(() => {
            this.loading = false;
          })
      },
      update(row) {
        this.loading = true;
        requestSender('post', 'evaluation/update', {
          id: row.id,
          name: row.name,
          description: row.description,
        })
          .then(() => {
            this.$notify.success({
              title: 'Сохранено',
              message: 'Элемент успешно сохранен'
            });
          })
          .finally(() => {
            this.loading = false;
          })
      }
    }
  }
</script>

<style>
  .evaluation {
    margin: 25px 0;
  }

  tr th {
    font-size: 16px;
  }

  tr td {
    font-size: 14px;
  }
</style>